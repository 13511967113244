import { SendOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, notification, Row, DatePicker } from "antd";
import React, {useEffect, useState} from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

const MoneyReturnModal = ({ invoice, client, total, loading, setLoading, dateIssued }) => {
  const { t } = useTranslation();

  const { TextArea } = Input;

  const [form] = Form.useForm();

  const [isOpen, setIsOpen] = useState(false);
  const [bankAccount, setBankAccount] = useState('');
  const [dateDue, setDateDue] = useState(moment().date(16));

  const normalizeDiacritics = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  let address = invoice?.country_branch_id === 2 ? `${client?.city_branch?.name} ${client.billing_address_street} ${client.billing_address_house_number}` : invoice?.building?.registration_number;

  const message = normalizeDiacritics(
    moment(invoice?.date_issued).add(1, 'days').format("YYYY") + "/" + moment(invoice?.date_issued).add(1, 'days').format("MM") +
    " " +
    address + `-${t("finance.moneyReturn.overpayment")}`
  );

  useEffect(() => {
    const getBankAccount = () => {
      const clientData = client?.represented_by ?? client;

      if (clientData?.currency_iso_4217 === "EUR" && clientData?.account_iban) {
        return clientData.account_iban;
      }

      if (clientData?.account_number) {
        return clientData?.account_prefix
          ? `${clientData.account_prefix}-${clientData.account_number}/${clientData.account_bank_code}`
          : `${clientData.account_number}/${clientData.account_bank_code}`;
      }

      return clientData.account_iban;
    };

    setBankAccount(getBankAccount());
  }, [client]);

  const handleOk = () => {
    if (!bankAccount) {
      notification.error({ message: t("finance.moneyReturn.accountDataNotFilled")});
      return;
    }
    setLoading(true);
    let payload = {
      country_branch_id_to: client?.city_branch?.country_branch_id || invoice?.city_branch?.country_branch_id,
      account_id_from: client?.city_branch?.account_id || invoice?.city_branch?.account_id,
      client_id_to: client?.id,
      amount: total,
      date: moment(dateDue)?.format("YYYY-MM-DD"),
      variable_symbol: invoice?.corrective_document?.invoice_number || invoice?.invoice_number,
      message: message,
      invoice_id: invoice?.corrective_document?.id || invoice?.id,
    };

    coreApi
      .post("payments/save-single-client-payment", { transactions: [{ ...payload }] })
      .then((res) => {
        notification.success({ message: res.data.message });
        setIsOpen(false);
      })
      .catch((err) => {
        notification.error({
          message: err?.response?.data?.message,
          description: err?.response?.data?.error,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <React.Fragment>
      <Button onClick={() => setIsOpen(!isOpen)} type="secondary" size="small">
        <SendOutlined />
      </Button>
      <Modal
        key={client?.id}
        open={isOpen}
        onCancel={() => setIsOpen(() => !isOpen)}
        title={t("buildings.moneyReturn")}
        okText={t("finance.moneyReturn.sendPaymentForApproval")}
        onOk={handleOk}
        okButtonProps={{ loading: loading }}
      >
        <Form form={form} layout="inline">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item name="account_to" label={t("finance.accounts.accountTo")} initialValue={bankAccount}>
                <Input disabled={true} bordered={false}></Input>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="variable_symbol"
                label={t("finance.transactions.variableSymbol")}
                initialValue={invoice?.corrective_document?.invoice_number || invoice?.invoice_number}
              >
                <Input disabled={true} bordered={false}></Input>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="amount"
                label={t("finance.moneyReturn.amount")}
                initialValue={Math.abs(total).toFixed(2)}
              >
                <Input disabled={true} bordered={false}></Input>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="message" label={t("finance.transactions.message")} initialValue={message}>
                <TextArea disabled={true} bordered={false} rows={2}></TextArea>
              </Form.Item>
            </Col>

            <Col span={16}>
              <Form.Item label={t("finance.moneyReturn.date")} name={"transaction_date"} initialValue={dateDue}>
                <DatePicker
                  allowClear={false}
                  allowEmpty={false}
                  onChange={(value) => setDateDue(value)}
                  style={{ width: "100%" }}
                  disabledDate={(current) =>
                    current.format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")
                  }
                ></DatePicker>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default MoneyReturnModal;
