import { Divider, Card, Col, Row, Space, Table, Tabs, Typography, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../api/calls";
import BuildingDetails from "../components/Buildings/BuildingDetails";
import BuildingUnitAddForm from "../components/Buildings/BuildingUnitAddForm";
import BuildingUnitContactPane from "../components/Buildings/BuildingUnitContactPane";
import BuildingInvoicePane from "../components/Buildings/BuildingInvoicePane";
import RecordActionsBar from "../components/RecordActionsBar";
import CustomTabs from "../components/custom/CustomTabs";
import { BankOutlined, InfoCircleOutlined } from "@ant-design/icons";
import ContactsPane from "../components/ContactsPane";
import PartnerLink from "../components/Partners/PartnerLink";
import CommunicationPane from "../components/CommunicationsPane";
import ClaimsPane from "../components/Claims/ClaimsPane";
import CleaningListComplex from "../components/Cleanings/CleaningListComplex";
import { ParseRegularityFromRruleDays } from "../helpers";
import {SlideDown} from "react-slidedown";
import ScheduleMassTerminate from "../components/Scheduler/MultiStepEdit/ScheduleMultiStepMassEdit";

// Gutter for <Row>
const gutter = [16, 16];

/**
 * Page with detail of building
 * @component
 * @alias BuildingProfile
 * @returns <Card /> with content
 */
const BuildingProfile = (props) => {
  const { t } = useTranslation();

  // States
  const [isLoading, setLoading] = useState(false);
  const [building, setBuilding] = useState({});
  const [redirectState, setRedirectState] = useState(false);
  const [currentTabKey, setCurrentTabKey] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedBuildingUnits, setSelectedBuildingUnits] = useState([]);
  const [buildingUnits, setBuildingUnits] = useState();

  const { TabPane } = Tabs;

  const selectRrules = (buildingUnitIds) => {
    setSelectedBuildingUnits(
      buildingUnits.filter((buildingUnit) => {
        return buildingUnitIds.includes(buildingUnit?.id);
      })
    );
  };

  useEffect(() => {
    setLoading(true);

    coreApi
      .get("buildings/" + props.match.params.id)
      .then((res) => {
        setBuilding(res.data);
        setBuildingUnits(res.data.building_units);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.match.params.id]);

  return (
    <React.Fragment key={building?.id}>
      <Row gutter={gutter}>
        <Col span={24}>
          <Card loading={isLoading}>
            <Row gutter={[0, 24]} style={{ display: "flex", justifyContent: "space-between" }}>
              {/* Name of building */}
              <div>
                <Typography.Title>
                  {building?.registration_number ? building.registration_number + " - " : ""}
                  {String(building?.reference)}
                </Typography.Title>

                <Space>
                  <Typography.Title level={5} type="secondary" style={{ color: "#1890ff", fontWeight: 400 }}>
                    <Link to={`/clients/${building?.client_id}`}>
                      <Space size={[6, 12]}>
                        <BankOutlined />
                        {building?.client?.name}
                      </Space>
                    </Link>
                  </Typography.Title>
                </Space>
              </div>

              {/* Actions buttons */}
              <RecordActionsBar
                redirectState={redirectState}
                setRedirect={() => {
                  setRedirectState(true);
                }}
                firstModalTitle={t("buildings.delete.alertTitle")}
                firstModalContent={t("buildings.delete.alertContent")}
                entityRoute="/buildings"
                recordId={building?.id}
              ></RecordActionsBar>
            </Row>

            {/* Tab sections of resource */}
            <CustomTabs props={props} onChange={(e) => { setCurrentTabKey(e) }}>
              <Tabs.TabPane key="#overview" tab={t("buildingUnits.overview")}>
                <BuildingDetails props={{ ...props }} building={building} setBuilding={setBuilding} />
              </Tabs.TabPane>

              <Tabs.TabPane key="#units" tab={t("buildings.buildingUnitsOverview")}>
                <BuildingUnitAddForm building={building} setBuilding={setBuilding} />
                <Col span={24}>
                  <SlideDown className={"my-dropdown-slidedown"} closed={selectedRows.length < 1}>
                    <Space style={{ marginTop: '20px' }}>
                      <ScheduleMassTerminate selectedBuildingUnits={selectedBuildingUnits} />
                    </Space>
                  </SlideDown>
                </Col>
                <Table
                  size="small"
                  pagination={{
                    showSizeChanger: true,
                  }}
                  style={{ height: "100%", marginTop: "16px" }}
                  loading={isLoading}
                  rowKey={"id"}
                  rowSelection={{
                    selectedRowKeys: selectedRows,
                    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
                    onChange: (selectedRowKeys) => {
                      selectRrules(selectedRowKeys);
                      setSelectedRows(selectedRowKeys);
                    },
                  }}
                  columns={[
                    {
                      title: t("common.address"),
                      dataIndex: "id",
                      sorter: (a, b) => a.house_number - b.house_number,
                      render: (_, row, index) => {
                        return (
                          <React.Fragment key={row?.id}>
                            <Link to={"/buildings/" + row.building_id + "/unit/" + row?.id} style={{ fontWeight: 500 }}>
                              {row.street + " " + row.house_number}
                            </Link>
                          </React.Fragment>
                        );
                      },
                    },
                    {
                      title: t("cleanings.partner"),
                      key: "partner",
                      render: (row) => {
                        return (
                          <PartnerLink partner={row?.service_rrules?.[row?.service_rrules?.length - 1]?.partner} />
                        );
                      },
                    },
                    {
                      title: t("schedules.cleaningDays"),
                      key: "cleaning_days",
                      dataIndex: "cleaning_days",
                      render: (days, element) => {
                        days = days[0]?.split(",");
                        return ParseRegularityFromRruleDays(days, element)
                          ?.map((day) => {
                            return t("rrules.byweekday." + day);
                          })
                          .join(", ");
                      },
                    },
                    {
                      title: t("buildingUnits.city"),
                      dataIndex: "city",
                      sorter: (a, b) => a.city.localeCompare(b.city),
                    },
                    {
                      title: t("buildingUnits.district"),
                      dataIndex: "district",
                      sorter: (a, b) => a.district.localeCompare(b.district),
                    },
                    {
                      title: t("schedules.pricePerUnit"),
                      render: () =>
                        new Intl.NumberFormat("cs-CZ", {
                          style: "currency",
                          currency: building?.citry_branch?.country_branch?.iso_4217_currency_code || "CZK",
                        }).format(building?.price_per_unit),
                    },
                    {
                      title: t("buildingUnits.floorCount"),
                      dataIndex: "floor_count",
                      sorter: (a, b) => a.floor_count - b.floor_count,
                    },
                    {
                      title: t("buildingUnits.groundFloorCount"),
                      dataIndex: "ground_floor_count",
                      sorter: (a, b) => a.ground_floor_count - b.ground_floor_count,
                    },
                    {
                      title: t("buildingUnits.basementFloorCount"),
                      dataIndex: "basement_floor_count",
                      sorter: (a, b) => a.basement_floor_count - b.basement_floor_count,
                    },
                  ]}
                  dataSource={building.building_units}
                  expandable={{
                    expandedRowRender: (buildingUnit) => {
                      return (
                        <p style={{ margin: 0 }}>
                          {buildingUnit?.cleaning_service_groups?.map((cleaningServiceGroup) => {
                            return (
                              <React.Fragment key={cleaningServiceGroup?.id}>
                                <strong>{cleaningServiceGroup?.name}</strong>

                                <div style={{ marginBottom: "6px", whiteSpace: "break-spaces" }}>
                                  {cleaningServiceGroup?.cleaning_service_group_contents?.map(
                                    (cleaningServiceGropContent) => (
                                      <em key={cleaningServiceGropContent.id}>{cleaningServiceGropContent.service}</em>
                                    )
                                  )}
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </p>
                      );
                    },
                    expandIcon: ({ expanded, onExpand, record, expandable }) =>
                      expandable && (
                        <Tooltip placement="top" title={t("buildings.showServiceContent")}>
                          <InfoCircleOutlined
                            onClick={(e) => onExpand(record, e)}
                            style={{ transition: "linear 1s 0.2s" }}
                          />
                        </Tooltip>
                      ),
                    rowExpandable: (buildingUnit) => buildingUnit?.cleaning_service_groups?.length > 0,
                  }}
                ></Table>
              </Tabs.TabPane>

              <Tabs.TabPane key="#issuedOffers" tab={t("buildings.issuedOffersAndOrders")}>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Divider orientation={"left"}>{t("buildings.issuedOffers")}</Divider>
                    <Table
                      size="small"
                      pagination={{ pageSize: 5, showSizeChanger: true }}
                      loading={isLoading}
                      dataSource={building.offers}
                      columns={[
                        {
                          title: t("offers.headerClientName"),
                          key: "client_id",
                          dataIndex: "header_client_name",
                          render: (text, row, index) => {
                            return (
                              <Link to={"/clients/" + row.client_id} style={{ borderBottom: "1px dotted" }}>
                                {text}
                              </Link>
                            );
                          },
                        },
                        {
                          title: t("offers.summaryDetails.total"),
                          key: "offer_id",
                          dataIndex: "price",
                          render: (text, row, index) => {
                            return (
                              <Link to={"/offers/" + row.id} style={{ fontWeight: 500 }}>
                                {new Intl.NumberFormat("cs-CZ", {
                                  style: "currency",
                                  currency: row.currency_iso_4217,
                                }).format(text)}
                              </Link>
                            );
                          },
                        },
                        {
                          title: t("offers.dateIssued"),
                          key: "dateIssued",
                          dataIndex: "date_issued",
                        },
                      ]}
                    ></Table>
                  </Col>
                  <Col span={12}>
                    <Divider orientation={"left"}>{t("buildings.ordersOverview")}</Divider>
                    <Table
                      size="small"
                      loading={isLoading}
                      dataSource={building.orders}
                      pagination={{ pageSize: 5, showSizeChanger: true }}
                      rowKey={"id"}
                      columns={[
                        {
                          title: t("offers.headerClientName"),
                          dataIndex: "header_client_name",
                          render: (text, row, index) => {
                            return (
                              <Link to={"/clients/" + row.client_id} style={{ borderBottom: "1px dotted" }}>
                                {text}
                              </Link>
                            );
                          },
                        },
                        {
                          title: t("offers.summaryDetails.total"),
                          dataIndex: "price",
                          render: (text, row, index) => {
                            return (
                              <Link to={"/orders/" + row.id} style={{ fontWeight: 500 }}>
                                {new Intl.NumberFormat("cs-CZ", {
                                  style: "currency",
                                  currency: row.currency_iso_4217,
                                }).format(text)}
                              </Link>
                            );
                          },
                        },
                        {
                          title: t("offers.dateIssued"),
                          dataIndex: "date_issued",
                        },
                      ]}
                    ></Table>
                  </Col>
                </Row>
              </Tabs.TabPane>

              <Tabs.TabPane key="#invoices" tab={t("buildingUnits.invoices")}>
                <BuildingInvoicePane contacts={building.contacts} />
              </Tabs.TabPane>

              <Tabs.TabPane key="#contacts" tab={t("buildingUnits.contacts")}>
                <Tabs defaultActiveKey="0">
                  <TabPane tab={t("contacts.onBuildings")} key="0">
                    <BuildingUnitContactPane
                      contacts={building.contacts}
                      contactableId={props.match.params.id}
                      contactableType={"building"}
                    />
                  </TabPane>

                  {building?.building_units?.map((buildingUnit, i) => {
                    return (
                      <>
                        <TabPane tab={`${buildingUnit?.street} ${buildingUnit?.house_number}`} key={buildingUnit?.id || i + 1}>
                          {buildingUnit?.contacts && (
                            <>
                              <ContactsPane
                                contacts={buildingUnit?.contacts}
                                contactable={{ id: buildingUnit?.id, type: "buildingUnit" }}
                              />
                            </>
                          )}
                        </TabPane>
                      </>
                    );
                  })}
                </Tabs>
              </Tabs.TabPane>

              <Tabs.TabPane key="#communication" tab={t("buildingUnits.communication")}>
                <CommunicationPane currentTabKey={currentTabKey} />
              </Tabs.TabPane>
              <Tabs.TabPane tab={t("cleanings.cleanings")} key="#cleanings">
                <CleaningListComplex building={building} currentTabKey={currentTabKey} />
              </Tabs.TabPane>
              <Tabs.TabPane tab={t("buildingUnits.claims")} key="#claims">
                <ClaimsPane building={building} currentTabKey={currentTabKey} />
              </Tabs.TabPane>
            </CustomTabs>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BuildingProfile;
