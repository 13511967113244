import { CheckOutlined, PlusOutlined, StopOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, InputNumber, notification, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import { OfferContext } from "../../pages/OfferProfile";
import CustomRegularityModal from "./CustomRegularityModal";

const OfferItemAdd = ({ regularityList, calculateHoursPerMonth, addCleaningGroup, isOrder, buildingUnitId }) => {
  const { t } = useTranslation();

  const { offer, refreshOffer } = useContext(OfferContext);
  const [isAdding, setAdding] = useState(false);
  const [regularityModalOpen, setRegularityModalOpen] = useState(false);

  const [addForm] = Form.useForm();

  const hourPrice = 170;

  let count = 0;
  let minutes = 0;
  let cleaningGroup = {};

  let hpm = 0;

  const handleCountChange = (value) => {
    count = value;
    handleCalculation();
  };

  const handleMinutesChange = (value) => {
    minutes = value;
    handleCalculation();
  };

  const handleCleaningGroupChange = (value) => {
    cleaningGroup = offer?.cleaning_service_groups?.find((group) => group.id === value) ?? mergedListOfValues.find((item) => item.id === value)
    handleCalculation();
  };

  const cleaningGroupRrule = (value) => {
    cleaningGroup = offer?.cleaning_service_groups?.find((group) => group.id === value) ?? mergedListOfValues.find((item) => item.id === value)

    return cleaningGroup.rrule_blueprint;
  }

  const handleCalculation = () => {
    hpm = calculateHoursPerMonth(cleaningGroup, minutes, count);

    if (hpm > 0) {
      addForm.setFieldsValue({ expected_hours_per_month: hpm.toFixed(3) });
      calculatePrice();
    }
  };

  const calculatePrice = () => {
    let price = hpm * hourPrice;
    addForm.setFieldsValue({ expected_price_per_month: price.toFixed(2) });
  };

  const standartValues = [
    { id: "1x TÝDNĚ", name: t("offers.frequencies.1xWEEKLY"), rrule_blueprint: "FREQ=WEEKLY;INTERVAL=1;BYDAY=MO" },
    { id: "2x TÝDNĚ", name: t("offers.frequencies.2xWEEKLY"), rrule_blueprint: "FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TH" },
    { id: "3x TÝDNĚ", name: t("offers.frequencies.3xWEEKLY"), rrule_blueprint: "FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,WE,FR" },
    { id: "4x TÝDNĚ", name: t("offers.frequencies.4xWEEKLY"), rrule_blueprint: "FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TH,SA,SU" },
    { id: "5x TÝDNĚ", name: t("offers.frequencies.5xWEEKLY"), rrule_blueprint: "FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TH,SA,SU,WE" },
    { id: "6x TÝDNĚ", name: t("offers.frequencies.6xWEEKLY"), rrule_blueprint: "FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TH,SA,SU,WE,FR" },
    { id: "7x TÝDNĚ", name: t("offers.frequencies.7xWEEKLY"), rrule_blueprint: "FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TH,SA,SU,WE,FR,TU" },
    { id: "1x 14 DNÍ", name: t("offers.frequencies.1x14DAYS"), rrule_blueprint: "FREQ=WEEKLY;INTERVAL=2;BYDAY=MO" },
    { id: "1x MĚSÍČNĚ", name: t("offers.frequencies.1xMONTHLY"), rrule_blueprint: "FREQ=MONTHLY;INTERVAL=1;BYMONTHDAY=1" },
    { id: "4x ROČNĚ", name: t("offers.frequencies.4xYEARLY"), rrule_blueprint: "FREQ=MONTHLY;INTERVAL=3;BYMONTH=1,4,7,10;BYMONTHDAY=1" },
    { id: "2x ROČNĚ", name: t("offers.frequencies.2xYEARLY"), rrule_blueprint: "FREQ=MONTHLY;INTERVAL=6;BYMONTH=1,7;BYMONTHDAY=1" },
    { id: "1x ROČNĚ", name: t("offers.frequencies.1xYEARLY"), rrule_blueprint: "FREQ=YEARLY;INTERVAL=1;BYMONTH=1;BYMONTHDAY=1" }
  ];

  const mergedListOfValues = standartValues.map(item1 => {
    const match = regularityList.find(item2 => item2.name === item1.name);
    if (match) {
      return {
        ...item1,
        id: match.id ?? item1.id,
        rrule_blueprint: match.rrule_blueprint ?? item1.rrule_blueprint
      };
    }
    return item1;
  });

  regularityList.forEach(item2 => {
    const match = mergedListOfValues.find(item1 => item1.name === item2.name);
    if (!match) {
      mergedListOfValues.push(item2);
    }
  });

  return (
    <Form
      form={addForm}
      onFinish={(values) => {
        let newItem = {
          service: values.service,
          cleaning_service_group_id: values.cleaning_service_group_id,
          offer_id: offer.id,
          building_unit_id: buildingUnitId,
          rrule_blueprint: cleaningGroupRrule(values.cleaning_service_group_id),
        };

        coreApi
          .post("cleaning-service-group-contents", newItem)
          .then((res) => {
            refreshOffer(res.data.offer);
            notification.success({ message: res.data.message });
          })
          .catch((err) => notification.error({ message: err?.response?.data?.message }));
        addForm.resetFields();
      }}
    >
      <Row gutter={[8, 0]}>
        {isOrder ? (
          ""
        ) : (
          <React.Fragment>
            <Divider orientation="left"></Divider>
            {isAdding ? (
              <>
                <Col span={3}>
                  <Form.Item
                    name="cleaning_service_group_id"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      placeholder={t("offers.itemPlaceholders.regularity")}
                      onChange={(value) => {
                        handleCleaningGroupChange(value);
                      }}
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              padding: 8,
                            }}
                          >
                            <button
                              style={{
                                flex: "none",
                                border: "none",
                                display: "block",
                                cursor: "pointer",
                              }}
                              onClick={() => setRegularityModalOpen(true)}
                            >
                              <PlusOutlined /> {t("common.add")}
                            </button>
                          </div>
                        </div>
                      )}
                    >
                      {mergedListOfValues.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                      {/*{standartValues.map((item, index) => (*/}
                      {/*  <Select.Option key={index} value={item.id}>*/}
                      {/*    {item.name}*/}
                      {/*  </Select.Option>*/}
                      {/*))}*/}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={14}>
                  <Form.Item
                    name="service"
                    hasFeedback
                    rules={[
                      {
                        message: "",
                      },
                    ]}
                  >
                    <TextArea autoSize={true}></TextArea>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Row gutter={[8, 8]}>
                    <Col>
                      <Button
                        htmlType="submit"
                        icon={<CheckOutlined />}
                        onClick={() => {
                          addForm
                            .validateFields()
                            .then((valid) => {
                              addForm.submit();
                              setAdding(false);
                            })
                            .catch((e) => {});
                        }}
                      ></Button>
                    </Col>
                    <Col>
                      <Button
                        icon={<StopOutlined />}
                        onClick={(e) => {
                          setAdding(false);
                          addForm.resetFields();
                        }}
                      ></Button>
                    </Col>
                  </Row>
                </Col>
              </>
            ) : (
              <Button
                disabled={isOrder}
                style={{ width: "100%" }}
                onClick={(e) => {
                  setAdding(true);
                }}
              >
                {t("common.add")}
              </Button>
            )}
          </React.Fragment>
        )}
      </Row>
      <CustomRegularityModal
        isModalOpen={regularityModalOpen}
        setModalOpen={setRegularityModalOpen}
        addCleaningGroup={addCleaningGroup}
      ></CustomRegularityModal>
    </Form>
  );
};

export default OfferItemAdd;
