import {Form, Select} from "antd";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

const DaySelect = ({ setDays, hasFeedback, name = "days", isRequired = false, placeholder = '', showLabel = true, className = "default-item-class" }) => {
  const { t } = useTranslation();

  const days = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];

  const handleChange = (values) => {
    setDays(values);
  };
  
  return (
    <Form.Item
      className={className}
      label={showLabel ? (placeholder.length ? placeholder : t("rrules.weekday")) : null}
      name={name}
      initialValue={setDays}
      hasFeedback={hasFeedback}
      rules={[
        {
          required: isRequired,
          message: t("schedules.rruleDayRequiredMessage"),
        },
      ]}
    >
      <Select
        mode={"multiple"}
        allowClear
        bordered
        placeholder={placeholder}
        onChange={handleChange}
      >
        {days.map((day, index) => (
          <Select.Option key={day + index} value={day}>
            {t("rrules.byweekday." + day)}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default DaySelect;
